import oumou_sangare from "../icons/oumou_sangare.jpg";
import "../styles/AboutUs.css";

export function AboutUs() {
  return (
    <div className="about-us-container">
      <h1>Bienvenue sur MaliEvents !</h1>

      <div className="image-section">
        <img className="oumou-sangare" src={oumou_sangare} alt="Oumou Sangaré en concert" />
      </div>

      <div className="sections-container">
        <div className="left-sections">
          <h4>Notre Mission</h4>
          <p>Notre mission est de connecter les individus et les entreprises à travers des événements
            culturels, artistiques et sportifs au Mali et dans la diaspora. Nous visons à créer une
            plateforme dynamique et inclusive qui permet à chacun de découvrir, participer et promouvoir
            des événements uniques. Nous nous efforçons de soutenir les organisateurs d'événements et de
            faciliter les partenariats avec les entreprises pour offrir des expériences enrichissantes à tous.
          </p>

          <h4>Notre Vision</h4>
          <p>Nous aspirons à devenir la référence incontournable pour tous ceux qui cherchent à explorer
            la scène des événements au Mali. Notre vision est de fournir une plateforme complète qui
            rassemble la diversité des événements maliens, qu'ils se déroulent au pays ou dans la diaspora,
            et de créer une communauté engagée et passionnée. Nous souhaitons mettre en valeur le riche
            patrimoine culturel du Mali et promouvoir des expériences mémorables pour les participants.
          </p>
        </div>

        <div className="right-sections">
          <h4>Nos Valeurs</h4>
          <p>Nos valeurs guident notre travail et définissent notre engagement envers nos utilisateurs et
            nos partenaires. Nous sommes animés par l'intégrité, l'innovation, la collaboration et la
            diversité. Nous croyons en la transparence et en l'équité dans nos relations avec tous les
            acteurs de la communauté des événements au Mali. Nous encourageons la créativité, la
            responsabilité sociale et le respect mutuel pour promouvoir un environnement inclusif et
            inspirant.
          </p>

          <h4>Notre Utilité</h4>
          <p>En tant que plateforme d'événements au Mali, nous sommes là pour vous aider à trouver et à
            participer aux événements qui correspondent à vos intérêts et à vos passions. Que vous soyez
            un artiste, un amateur de musique, un fan de sport ou simplement à la recherche de nouvelles
            expériences culturelles, notre utilité réside dans notre capacité à vous connecter avec des
            événements pertinents et à vous offrir une plateforme conviviale pour découvrir et promouvoir
            la diversité des événements maliens.
          </p>
        </div>
      </div>
    </div>
  );
}
