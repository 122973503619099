import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import save_icon from "../icons/save.png";
import person_icon from "../icons/user.png";
import "../styles/Navbar.css";

export function Navbar(){
    const [showMenu, setShowMenu] = useState(false);

    // Toggle the menu visibility
    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };

    const navigate = useNavigate();

    function logout(){
        window.localStorage.removeItem("token");
        alert("Vous êtes déconnecté(e)");
        navigate("/auth");
    }
    const isLogged = window.localStorage.getItem("token") !== undefined && window.localStorage.getItem("token") !== null;
    
    function handleLogoClick(){
        navigate("/");
    }
    return(
        <div className="navbar">
            {/* Burger menu */}
            <div className="burger-menu" onClick={toggleMenu}>
                <div className={`burger-line ${showMenu ? 'open' : ''}`} />
                <div className={`burger-line ${showMenu ? 'open' : ''}`} />
                <div className={`burger-line ${showMenu ? 'open' : ''}`} />
            </div>
            
            <h1 className="logo" onClick={handleLogoClick}>
                <span className="green">M</span>
                <span className="green">a</span>
                <span className="green">l</span>
                <span className="green">i</span>
                <span className="yellow">E</span>
                <span className="yellow">v</span>
                <span className="yellow">e</span>
                <span className="red">n</span>
                <span className="red">t</span>
                <span className="red">s</span>
            </h1>

            {/* Menu items */}
            {showMenu && (
                <div className="menu-items">
                <Link to="/saved-events" className="menu-item">
                    Enregistrement
                </Link>
                <Link to="/no-services" className="menu-item">
                    Nos Services
                </Link>
                <Link to="/about-us" className="menu-item">
                    A Propos
                </Link>
                <Link to="/contact-us" className="menu-item">
                    Contact
                </Link>
                </div>
            )}

            
            <Link to="/create-event"    className="create"> Créer mon événement</Link>
            <Link to="/saved-events"    className="save"> <img className="save-icon" src={save_icon} alt="Account"/> </Link>
            {!isLogged ?  (<Link to="/auth"> <img className="account-icon" src={person_icon} alt="Account"/></Link>) 
            : (<button className="logout-button" onClick={logout}>Logout</button>)}
            
        </div>
    );
}