import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import "../styles/Inscription.css";
import { backendUrl } from '../utils/contants';

export function Signup(){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    async function onSubmit(event: { preventDefault: () => void; }){
        if(password === confirmPassword){
            event.preventDefault();
            try {
                await axios.post(`${backendUrl}/api/auth/signup`,
                {
                    email:username, 
                    password,
                });
                navigate("/");
                alert("Inscription réussie!");
            } catch (error) {
                alert("Inscription échouée!");
                console.error(error);
            }
        }
        else{
            alert("Identifiant ou mot de passe incorrecte");
        }
    }

    return(
        <Form 
            username={username}
            setUsername={setUsername}
            password={password} 
            setPassword={setPassword} 
            confirmPassword={confirmPassword} 
            setConfirmPassword={setConfirmPassword} 
            label="Inscription"
            onSubmit={onSubmit}
            />
    );
};




interface FormProps{
    username: string;
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    confirmPassword: string;
    setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}

function Form(props: FormProps){
    return(
        <div className="auth-container">
            <form onSubmit={props.onSubmit}>
                <h2> {props.label}</h2>
                <div className="form-group">
                    <label htmlFor="username">Email <span className="required-field">*</span> </label>
                    <input 
                        className="form-input"
                        type="text"
                        id="username" 
                        placeholder="prenom.nom@exemple.ml"
                        value={props.username}
                        onChange={(event) => props.setUsername(event.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Mot de passe <span className="required-field">*</span> </label>
                    <input 
                        className="form-input"
                        type="password" 
                        id="password" 
                        placeholder="mot de passe"
                        value={props.password}
                        onChange={(event) => props.setPassword(event.target.value)} />
                </div>
                
                <div className="form-group">
                    <label htmlFor="password">Confirmation du mot de passe <span className="required-field">*</span> </label>
                    <input 
                        className="form-input"
                        type="password" 
                        id="password" 
                        placeholder="mot de passe"
                        value={props.confirmPassword}
                        onChange={(event) => props.setConfirmPassword(event.target.value)} />
                </div>
                <button className="form-button" type="submit">{props.label}</button>
            </form>
        </div>
    );
};