import axios from "axios";
import React, { useState } from "react";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Link, useNavigate } from "react-router-dom";
import "../styles/Create-event.css";
import { backendUrl } from "../utils/contants";
const eventTypes = [
    {value: "concert", label: "Concert"},
    {value: "festival", label: "Festival"},
    {value: "culture", label: "Culture"},
    {value: "forum_salon", label: "Forum / Salon"},
    {value: "theatre", label: "Théatre"},
    {value: "cinema", label: "Cinéma"},
    {value: "sport", label: "Sport"},
    {value: "discotheque", label: "Discothèque"},
    {value: "associatif_ong", label: "Associatif / ONG"},
];

export function CreateEvent() {
    const [title, setTitle] = useState("");
    const [eventType, setEventType] = useState<string>('');
    const [eventProfileImage, setEventProfileImage] = useState<string >();
    const [startingDate, setStartingDate] = useState("");
    const [endingDate, setEndingDate] = useState("");
    const [eventAddress, setEventAddress] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventImage, setEventImage] = useState<string >();
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactWhatSapp, setContactWhatSapp] = useState("");
    const [contactLinkedIn, setContactLinkedIn] = useState("");
    const [contactFacebook, setContactFacebook] = useState("");
    const [contactInstagram, setContactInstagram] = useState("");
    const [contactTwitter, setContactTwitter] = useState("");
    const [contactTikTok, setContactTikTok] = useState("");
    const [contactSponsorEmail, setContactSponsorEmail] = useState("");
    const [contactSponsorPhone, setContactSponsorPhone] = useState("");

    const navigate = useNavigate();

    function navigateToSignup(){
        navigate("/inscription");
    }

    async function handleSubmit(event: { preventDefault: () => void; }){
        event.preventDefault();

        const formData = new FormData();
        formData.append("title", title);
        formData.append("eventType", eventType);
        formData.append("eventAddress", eventAddress);
        if(eventProfileImage){
            formData.append("eventProfileImage", eventProfileImage);
        }
        formData.append("startingDate", startingDate);
        formData.append("endingDate", endingDate);
        formData.append("eventDescription", eventDescription);
        if(eventImage){
            formData.append("eventImage", eventImage);
        }
        formData.append("contactName", contactName);
        formData.append("contactEmail", contactEmail);
        formData.append("contactPhone", contactPhone);
        formData.append(
            "contactWhatSapp",
            `https://wa.me/${contactWhatSapp}`
        );
        formData.append("contactLinkedIn", contactLinkedIn);
        formData.append("contactFacebook", contactFacebook);
        formData.append("contactInstagram", contactInstagram);
        formData.append("contactTwitter", contactTwitter);
        formData.append("contactTikTok", contactTikTok);
        formData.append("contactSponsorEmail", contactSponsorEmail);
        formData.append("contactSponsorPhone", contactSponsorPhone);
        try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const response = await axios.post(`${backendUrl}/api/event/`,
                formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                    },
                }   
           );

            
            navigate("/");
            alert("Événement créé!");
        } catch (error) {
            alert("Création de l'événement échouée!");
            console.error(error);
        }
    }
    const isLogged = window.localStorage.getItem("token") !== undefined && window.localStorage.getItem("token") !== null;
    const userId = window.localStorage.getItem("userId") || "";
    return(
        <div>
            {isLogged ? 
                <EventForm 
                    title={title}
                    setTitle={setTitle}
                    eventType={eventType}
                    setEventType={setEventType}
                    eventProfileImage={eventProfileImage}
                    setEventProfileImage={setEventProfileImage}
                    startingDate={startingDate}
                    setStartingDate={setStartingDate}
                    endingDate={endingDate}
                    eventAddress={eventAddress}
                    setEventAddress={setEventAddress}
                    setEndingDate={setEndingDate}
                    eventDescription={eventDescription}
                    setEventDescription={setEventDescription}
                    eventImage={eventImage}
                    setEventImage={setEventImage}
                    contactName={contactName}
                    setContactName={setContactName}
                    contactEmail={contactEmail}
                    setContactEmail={setContactEmail}
                    contactPhone={contactPhone}
                    setContactPhone={setContactPhone}
                    contactWhatSapp={contactWhatSapp}
                    setContactWhatSapp={setContactWhatSapp}
                    contactLinkedIn={contactLinkedIn}
                    setContactLinkedIn={setContactLinkedIn}
                    contactFacebook={contactFacebook}
                    setContactFacebook={setContactFacebook}
                    contactInstagram={contactInstagram}
                    setContactInstagram={setContactInstagram}
                    contactTwitter={contactTwitter}
                    setContactTwitter={setContactTwitter}
                    contactTikTok={contactTikTok}
                    setContactTikTok={setContactTikTok}
                    contactSponsorEmail={contactSponsorEmail}
                    setContactSponsorEmail={setContactSponsorEmail}
                    contactSponsorPhone={contactSponsorPhone}
                    setContactSponsorPhone={setContactSponsorPhone}
                    userOwner={userId}
                    onSubmit={handleSubmit}
                />
            : 
                <div>
                    <p className="log-to-create"> 
                    Vous souhaitez publier un événement ?
                        <span className="log-to-create-green-text">
                            <Link className="log-to-create-green-text" to="/auth"> Connectez-vous pour créer un événement</Link>
                        </span>
                    </p>
                    <button className="log-to-create-form-button" onClick={navigateToSignup}>Créer un compte</button>
                </div>
            }
        </div>
    );

};

interface EventFormProps{
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    eventType: string;
    setEventType: React.Dispatch<React.SetStateAction<string>>;
    eventProfileImage: string | undefined;
    setEventProfileImage: React.Dispatch<React.SetStateAction<string |undefined>>;
    startingDate: string;
    setStartingDate: React.Dispatch<React.SetStateAction<string>>;
    endingDate: string;
    setEndingDate: React.Dispatch<React.SetStateAction<string>>;
    eventAddress: string;
    setEventAddress: React.Dispatch<React.SetStateAction<string>>;
    eventDescription: string;
    setEventDescription: React.Dispatch<React.SetStateAction<string>>;
    eventImage: string | undefined;
    setEventImage: React.Dispatch<React.SetStateAction<string | undefined>>;
    contactName: string;
    setContactName: React.Dispatch<React.SetStateAction<string>>;
    contactEmail: string;
    setContactEmail: React.Dispatch<React.SetStateAction<string>>;
    contactPhone: string;
    setContactPhone: React.Dispatch<React.SetStateAction<string>>;
    contactWhatSapp: string;
    setContactWhatSapp: React.Dispatch<React.SetStateAction<string>>;
    contactLinkedIn: string;
    setContactLinkedIn: React.Dispatch<React.SetStateAction<string>>;
    contactFacebook: string;
    setContactFacebook: React.Dispatch<React.SetStateAction<string>>;
    contactInstagram: string;
    setContactInstagram: React.Dispatch<React.SetStateAction<string>>;
    contactTwitter: string;
    setContactTwitter: React.Dispatch<React.SetStateAction<string>>;
    contactTikTok: string;
    setContactTikTok: React.Dispatch<React.SetStateAction<string>>;

    contactSponsorEmail: string;
    setContactSponsorEmail: React.Dispatch<React.SetStateAction<string>>;
    contactSponsorPhone: string;
    setContactSponsorPhone: React.Dispatch<React.SetStateAction<string>>;

    userOwner: string | null;
    
    onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}

function EventForm(props: EventFormProps){
    return(
    <div className="create-event">
    <h2>Create event</h2>
    <form onSubmit={props.onSubmit}>
        <label htmlFor="title">Titre de l'événement <span className="required-field">*</span>
        </label>
        <input
            className="form-input"
            type="text" 
            id="title"
            name="title"
            placeholder="Conférence sur l'emploi des jeunes,etc..."
            value={props.title}
            onChange={(event) => props.setTitle(event.target.value)} />
        <label htmlFor="eventType">Type de l'événement <span className="required-field">*</span>
        </label>
        <Dropdown
            options={eventTypes}
            value={props.eventType}
            onChange={(type) => props.setEventType(type.value)}
            placeholder="Choisissez le type d'événement"
        />

        <label htmlFor="eventProfileImageLabel">Image de profil de l'événement <span className="required-field">*</span>
        </label>
        <input 
            className="form-input"
            type="file"
            id="eventProfileImage" 
            name="eventProfileImage" 
                    onChange={(event) => {
                
                        if (event.target.files && event.target.files.length > 0) {

                            const selectedFile = event.target.files[0]
                            let reader = new FileReader();
                            reader.onload = (e) => {
                            props.setEventProfileImage(e.target?.result as unknown as string);
                        
    };
    reader.readAsDataURL(selectedFile);
                        }
                        

                if(event.target.files && event.target.files.length > 0){ 
                    
                }
            }}
            />
        <label htmlFor="Dates">Du <span className="required-field">*</span>
        </label>
        <input 
            className="form-input"
            type="date"
            id="startingDate" 
            name="startingDate"
            value={props.startingDate}
            onChange={(event) => props.setStartingDate(event.target.value)} />
        <h3>Au <span className="required-field">*</span>

        </h3>
        <input 
            className="form-input"
            type="date"
            id="endingDate" 
            name="endingDate"
            value={props.endingDate}
            onChange={(event) => props.setEndingDate(event.target.value)} />
        <label htmlFor="eventAddress">Adresse de l'événement <span className="required-field">*</span>
        </label>
        <input 
            className="form-input"
            type="text" 
            id="eventAddress"
            name="eventAddress"
            placeholder="Hôtel de l'Amitié de Bamako"
            value={props.eventAddress}
            onChange={(event) => props.setEventAddress(event.target.value)} />
        <label htmlFor="eventDescription">Description de l'événement <span className="required-field">*</span>
        </label>
        <textarea 
            className="form-textarea"
            id="description" 
            name="description"
            placeholder="Soyez nombreux à assister au festival du Mali,etc..."
            value={props.eventDescription}
            onChange={(event) => props.setEventDescription(event.target.value)}
            ></textarea> 
        <label htmlFor="eventImageLabel">Affiche de l'événement <span className="required-field">*</span>
        </label>
        <input 
            className="form-input"
            type="file"
            id="eventImage" 
            name="eventImage" 
                    onChange={(event) => {
                
                        if (event.target.files && event.target.files.length > 0) {

                            const selectedFile = event.target.files[0]
                            let reader = new FileReader();
                            reader.onload = (e) => {
                                props.setEventImage(e.target?.result as unknown as string);
                                
    };
    reader.readAsDataURL(selectedFile);
                        }

                
            }}
            />
        
        <h3>Veuillez remplir le formulaire de contact ci-dessous</h3>
        <label htmlFor="Contact">Contact</label>
        <h3>Nom <span className="required-field">*</span>

        </h3>
        <input 
            className="form-input"
            type="text" 
            id="contactName"
            name="contactName"
            placeholder="Prénom et Nom de l'organisateur de l'événement"
            value={props.contactName}
            onChange={(event) => props.setContactName(event.target.value)} />
         <h3>Adresse mail <span className="required-field">*</span>

         </h3>
        <input 
            className="form-input"
            type="text" 
            id="contactEmail"
            name="contactEmail"
            placeholder="votre-email@domaine.com"
            value={props.contactEmail}
            onChange={(event) => props.setContactEmail(event.target.value)} />
        <h3>Numéro de téléphone <span className="required-field">*</span>

        </h3>
        <input 
            className="form-input"
            type="text" 
            id="contactPhone"
            name="contactPhone"
            placeholder="+223 84572356"
            value={props.contactPhone}
            onChange={(event) => props.setContactPhone(event.target.value)} />
        <h3>Numéro WhatsApp</h3>
        Pour le contact WhatsApp, ne mettez pas d'indicatif
        <input 
            className="form-input"
            type="text" 
            id="contactWhatSapp"
            name="contactWhatSapp"
            placeholder="75679804"
            value={props.contactWhatSapp}
            onChange={(event) => props.setContactWhatSapp(event.target.value)} />
        <h3>Lien vers votre page LinkedIn</h3>
        <input 
            className="form-input"
            type="text" 
            id="contactLinkedIn"
            name="contactLinkedIn"
            placeholder="www.linkedin.com/in/my-linkedin"
            value={props.contactLinkedIn}
            onChange={(event) => props.setContactLinkedIn(event.target.value)} />
        <h3>Lien vers votre page Facebook</h3>
        <input 
            className="form-input"
            type="text" 
            id="contactFacebook"
            name="contactFacebook"
            placeholder="www.facebook.com/lien-vers-ma-page"
            value={props.contactFacebook}
            onChange={(event) => props.setContactFacebook(event.target.value)} />
        <h3>Lien vers votre page Instagram</h3>
        <input 
            className="form-input"
            type="text" 
            id="contactInstagram"
            name="contactInstagram"
            placeholder="www.instagram.com/lien-vers-ma-page"
            value={props.contactInstagram}
            onChange={(event) => props.setContactInstagram(event.target.value)} />
        <h3>Lien vers votre page Twitter</h3>
        <input 
            className="form-input"
            type="text" 
            id="contactTwitter"
            name="contactTwitter"
            placeholder="www.twitter.com/lien-vers-ma-page"
            value={props.contactTwitter}
            onChange={(event) => props.setContactTwitter(event.target.value)} />
        <h3>Lien vers votre page TikTok</h3>
        <input 
            className="form-input"
            type="text" 
            id="contactTikTok"
            name="contactTikTok"
            placeholder="www.tiktok.com/lien-vers-ma-page"
            value={props.contactTikTok}
            onChange={(event) => props.setContactTikTok(event.target.value)} />


        <h3>Des entreprises, ONGs, ou Associations seraient peut-être intéressés pour vous 
            sponsoriser.<span> Renseigner une adresse mail et un numéro de téléphone spécial sur lequel de potentiels
            sponsors pourront vous contacter</span></h3>
        <label htmlFor="ContactSponsor">Contact pour sponsoring</label>
        <h3>Adresse email pour sponsoring <span className="required-field">*</span>

        </h3>
        <input 
            className="form-input"
            type="text" 
            id="contactSponsorEmail"
            name="contactSponsorEmail"
            placeholder="email@domaine.com"
            value={props.contactSponsorEmail}
            onChange={(event) => props.setContactSponsorEmail(event.target.value)} />
        <h3>Numéro de téléphone pour sponsoring <span className="required-field">*</span>

        </h3>
        <input 
            className="form-input"
            type="text" 
            id="contactSponsorPhone"
            name="contactSponsorPhone"
            placeholder="+223 78465839"
            value={props.contactSponsorPhone}
            onChange={(event) => props.setContactSponsorPhone(event.target.value)} />
        <button className="form-button" type="submit">Valider</button>
    </form>
</div>
);
};