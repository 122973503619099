import "../styles/Terms.css";

export function Terms() {
  return (
    <div className="terms">
      <h1>Conditions générales d'utilisation</h1>
      <p className="contract">Ce contrat a été modifié pour la dernière fois le 16 Juillet 2023.</p>

      <p>
        Bienvenue sur le site MaliEvents ! En utilisant notre site, vous acceptez les conditions 
        générales d'utilisation suivantes :
      </p>

      <div id="intellectual-property">
        <h2>Propriété intellectuelle</h2>
        <p>
          Tout le contenu du site, y compris les textes, images, logos, icônes et logiciels, est la 
          propriété de MaliEvents ou de ses fournisseurs de contenu et est protégé par les lois internationales 
          sur le droit d'auteur.
        </p>
      </div>

      <div id="platform-usage">
        <h2>Utilisation du site</h2>
        <p>
          Vous êtes autorisé à utiliser le site uniquement à des fins légitimes. Vous n'êtes pas autorisé à 
          utiliser le site de quelque manière qui pourrait endommager, désactiver, surcharger ou altérer le 
          site ou interférer avec l'utilisation et le plaisir d'autres utilisateurs.
        </p>
      </div>

      <div id="event-publication">
        <h2>Publication des événements</h2>
        <p>
            <span className="forbidden">MaliEvents prohibe la publication d'événement lié aux contenus suivants:</span>
        </p>
        <p>
          MaliEvents interdit la publication de tout événement lié à la <span className="forbidden">sexualité</span> (mouvement LGBTQ+, 
          mouvements féministes ou masculinistes, etc.). Si de tels événements sont publiés, ils seront 
          supprimés dès que l'équipe en sera informée.
        </p>
        <p>
          MaliEvents interdit la publication de tout événement lié à <span className="forbidden">l'ethnicité </span>(race, ethnies, etc.). 
          Si de tels événements sont publiés, ils seront supprimés dès que l'équipe en sera informée.
        </p>
        <p>
          MaliEvents interdit la publication de tout événement lié à la <span className="forbidden">politique </span>(mouvements politiques, 
          réunions politiques, etc.). Si de tels événements sont publiés, ils seront supprimés dès que 
          l'équipe en sera informée.
        </p>
        <p>
          MaliEvents interdit la publication de tout événement lié à la <span className="forbidden">religion </span>(réunions religieuses, 
          mouvements religieux, etc.). Si de tels événements sont publiés, ils seront supprimés dès que 
          l'équipe en sera informée.
        </p>
      </div>

      <div>
        <h2>Responsabilité</h2>
        <p>
          MaliEvents ne sera pas responsable de toute perte ou dommage de quelque nature que ce soit 
          résultant de l'utilisation du site.
        </p>
      </div>

      <div>
        <h2>Sécurité de vos informations</h2>
        <p>
          Nous prenons des mesures de sécurité pour protéger vos informations contre l'accès, l'utilisation 
          ou la divulgation non autorisés. Nous utilisons également des mesures de sécurité pour protéger 
          vos informations contre les pertes, les destructions accidentelles et les altérations.
        </p>
      </div>

      <div>
        <h2>Modifications des conditions d'utilisation</h2>
        <p>
          MaliEvents se réserve le droit de modifier ces conditions générales d'utilisation à tout moment. 
          Il est de votre responsabilité de vérifier régulièrement les conditions d'utilisation pour vous 
          assurer de les accepter.
        </p>
      </div>

      <div>
        <h2>Contact</h2>
        <p>
          Si vous avez des questions ou des préoccupations concernant nos conditions générales d'utilisation, 
          n'hésitez pas à nous contacter à l'adresse suivante : malievents223@gmail.com
        </p>
      </div>
    </div>
  );
}
