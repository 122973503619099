import "../styles/HowToUse.css";

export function HowToUse() {
  return (
    <div className="how-to-use">
      <h1>Comment utiliser MaliEvents ?</h1>
        <div className="for-promotor">
            <h2>Vous êtes un organisateur/promoteur d'événement ?</h2>
            <div>
                
                Pour utiliser MaliEvents en tant qu'organisateur ou promoteur d'événement, suivez les étapes suivantes :
                
            </div>
            <ol>
                <ul><strong>1. </strong>Créez un compte sur MaliEvents en cliquant sur "S'inscrire" en 
                haut à droite de la page.</ul>
                <ul><strong>2. </strong>Connectez-vous à votre compte et accédez à votre tableau de bord.</ul>
                <ul><strong>3. </strong>Ajoutez les détails de 
                votre événement, tels que la date, l'emplacement, la description, etc.</ul>
                <ul><strong>4. </strong>Une fois votre événement publié, il sera visible par la communauté 
                de MaliEvents et les utiulsateurs intéressés pourront s'inscrire.</ul>
              </ol>
            <div>
                
            Pour toute question ou assistance supplémentaire, n'hésitez pas à nous contacter via la page 
            "Contact".
                
        </div>
        </div>
        <div className="for-companies">
            <h2>Vous êtes une entreprise qui souhaite sponsoriser un événement ?</h2>
            <div>
            
            Si vous êtes une entreprise intéressée par la sponsorisation d'un événement via MaliEvents, 
            veuillez suivre les étapes suivantes :
            
            </div>
            <ol>
            <ul><strong>1. </strong>Explorez la liste des événements disponibles sur MaliEvents pour 
            trouver ceux qui correspondent à vos objectifs de sponsoring.</ul>
            <ul><strong>2. </strong>Cliquez sur l'événement que vous souhaitez sponsoriser.</ul>
            <ul><strong>3. </strong>Cliquez sur le bouton "Devenir Sponsor" pour être redirigé vers une 
            conversation WhatsApp avec l'équipe de MaliEvents.</ul>
            <ul><strong>4. </strong>Discutez des détails de la sponsorisation avec l'équipe de MaliEvents 
            pour parvenir à un accord mutuellement bénéfique.</ul>
            <ul><strong>5. </strong>Une fois l'accord conclu, votre entreprise pourra avoir son logo 
            associé à l'événement sponsorisé.</ul>
            </ol>
        </div>
    </div>
  );
}
