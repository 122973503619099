import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/Saved-events.css";
import { backendUrl } from '../utils/contants';

type Event = {
    _id: string;
    title: string;
    eventProfileImage: string | undefined;
    startingDate: string;
    endingDate: string;
    eventAddress: string;
    eventDescription: string;
    eventImage: string | undefined;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactWhatSapp: string;
    contactLinkedIn: string;
    contactFacebook: string;
    contactInstagram: string;
    contactTwitter: string;
    contactTikTok: string;
    contactSponsorEmail: string;
    contactSponsorPhone: string;
}

type EventProps = {
    data:Event
}

const EventDisplayer = ({ data }: EventProps): JSX.Element => (
    <Link to={`/${data._id}`} className="event-link">
      <div className="event-container">
        <h1 className="event-title">{data.title}</h1>
        <img className="event-image" src={data.eventProfileImage} alt="" />
        <h4 className="event-dates">
          Du {new Date(data.startingDate).toLocaleDateString()} au {new Date(data.endingDate).toLocaleDateString()}
        </h4>
        <h4 className="event-address">À {data.eventAddress}</h4>
      </div>
    </Link>
);

export function SavedEvents() {

    const [events, setEvents] = useState<Event[]>([])

    const isLogged = window.localStorage.getItem("token") !== undefined && window.localStorage.getItem("token") !== null;
    
    useEffect(() => {
        if (isLogged) {
            const fetchAllEvents = async () => {
                try {
                    const axiosFecthEventsRep = await axios.get<Event[]>(`${backendUrl}/api/event/savedEvents`, {
                        headers: {
                            'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                        }
                    });
                    const events = axiosFecthEventsRep.data;
                    setEvents(events);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchAllEvents();
        }
    }, [isLogged]);

    const navigate = useNavigate();
    
    function navigateToSignup(){
        navigate("/inscription");
    }

    return(
        <div>
            {isLogged ? (
                <div className="events-container">
                    {events.map(event => <EventDisplayer data={event} />)}
                </div>
             ) : (
                <div>
                    <p className="log-to-create"> 
                    Vous ne souhaitez pas perdre de vue un événement ?
                        <span className="log-to-create-green-text">
                            <Link className="log-to-create-green-text" to="/auth"> Connectez-vous pour enregistrer un événement</Link>
                        </span>
                    </p>
                    <button className="log-to-create-form-button" onClick={navigateToSignup}>Créer un compte</button>
                </div>
            )}        
        </div>
    );
};