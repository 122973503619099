import { Link } from "react-router-dom";
import { Login } from "../inside_components/Login";
import "../styles/Auth.css";

export function Auth() {
    return(
        <div className="auth">
            <h1 className="logo">
                <span className="green">M</span>
                <span className="green">a</span>
                <span className="green">l</span>
                <span className="green">i</span>
                <span className="yellow">E</span>
                <span className="yellow">v</span>
                <span className="yellow">e</span>
                <span className="red">n</span>
                <span className="red">t</span>
                <span className="red">s</span>
            </h1>
            <Login />
            <p className="create-account">Pas encore inscrit ? 
                <span className="green-text">
                    <Link className="green-text" to="/inscription"> créez un compte</Link>
                </span>
            </p>

            <p className="create-account"> 
                <span className="green-text">
                    <Link className="green-text" to="/contact-us"> Mot de passe oublié ? Contactez-nous !</Link>
                </span>
            </p>

        </div>
    );
};


