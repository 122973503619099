import { faFacebook, faInstagram, faLinkedin, faTiktok, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import "../styles/ContactUs.css";
import { backendUrl } from "../utils/contants";

export function ContactUs(){
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);

    function handleDropDownToggle(){
        setIsDropDownOpen(!isDropDownOpen);
    }

    const handleInputChange = (e: { target: { name: any; value: any; }; }) =>{
        setFormData({...formData, [e.target.name]: e.target.value});
    }
    
    const handleSubmit = async (e: { preventDefault: () => void; })=>{
        e.preventDefault();
        try {
            await axios.post(`${backendUrl}/api/event/contact-us`, formData);
            //Reset the form
            setFormData({
                name:"",
                email:"",
                message:"",
            });
            alert("Message envoyé avec succès!");
        } catch (error) {
            console.error(error);
            alert("Échec d'envoi du message");
        }
    };

    return(
        <div>
            <div className="dropdown" onClick={handleDropDownToggle}>
                Retrouvez-nous sur
                {
                    isDropDownOpen ? (
                        <div className="dropdown-content">
                            <div>Facebook: MaliEvents<span className="space"> </span> </div>
                            <div>Instagram: malievents223</div>
                            <div>LinkedIn: MaliEvents</div>
                            <div>Twitter: malie_vents223</div>
                            <div>TikTok: malievents</div>
                            <div>WhatsApp: +223 83398062</div>
                            <div>Email: malievents223@gmail.com</div>
                        </div>
                    )
                    : null
                }
            </div>

            <div className="social-media-icons">
                <a href="https://wa.me/83398062" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100093168354814" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="mailto:malievents223@gmail.com" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faEnvelope} />
                </a>

                <a href="https://instagram.com/malievents223?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://www.linkedin.com/company/malievents/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="https://twitter.com/mali_events223?s=21&t=cj88NEnob5qk0zynygDB2w" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://www.tiktok.com/@malievents?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTiktok} />
                </a>
            </div>
        
            <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            Nom <span className="required-field">*</span>
          </label>
          <input
            className="form-input"
            type="text"
            name="name"
            id="name"
            placeholder="Votre nom"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">
            Email <span className="required-field">*</span>
          </label>
          <input
            className="form-input"
            type="email"
            name="email"
            id="email"
            placeholder="Votre adresse mail"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">
            Message <span className="required-field">*</span>
          </label>
          <textarea
            className="form-textarea"
            name="message"
            id="message"
            placeholder="Votre message"
            value={formData.message}
            onChange={handleInputChange}
            required
          />
        </div>

        <button className="form-button" type="submit">Valider</button>
      </form>
    </div>
    );
};