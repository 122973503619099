import { faFacebook, faInstagram, faLinkedin, faTiktok, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/Footer.css";

export function Footer() {

    return(
        <div className="footer">
            <div className="sb__footer section__padding">
            <hr></hr>
                <div className="sb__footer-links">
                    <div className="sb__footer-links_div">
                        <h4>Business</h4>
                        <a href="/no-services#publication-section">
                            <p>Publier un événement</p>
                        </a>
                        <a href="/no-services#sponsorisation-section">
                            <p>Sponsoriser un événement</p>
                        </a>
                        <a href="/partenariat">
                            <p>Partenariat</p>
                        </a>
                    </div>
                    <div className="sb__footer-links_div">
                        <h4>Information</h4>
                        <a href="/about-us">
                            <p>Qui sommes-nous ?</p>
                        </a>
                        <a href="/comment-ca-marche">
                            <p>Comment ça marche ?</p>
                        </a>
                        <a href="/policy">
                            <p>Politique de confidentialité</p>
                        </a>
                        <a href="/terms">
                            <p>Conditions d'utilisation</p>
                        </a>
                        
                        <a href="/contact-us">
                            <p>Nous Contacter</p>
                        </a>
                    </div>
                    
                    <div className="sb__footer-links_div">
                        <h4>Nos Services</h4>
                        <a href="/about-us">
                            <p>A Propos de Nous</p>
                        </a>
                        <a href="/no-services#publication-section">
                            <p>Service de publication d'événement</p>
                        </a>
                        <a href="/no-services#sponsorisation-section">
                            <p >Service de sponsorisation d'événement</p>
                        </a>
                        <a href="/no-services#ticket-selling-section">
                            <p >Vente de ticket pour des événements</p>
                        </a>
                        <a href="/contact-us">
                            <p>Contact</p>
                        </a>
                    </div>
                    <div className="sb__footer-links_div">
                        <h4>Suivez-nous sur</h4>
                        <div className="social-media-icons">
                            <a href="https://wa.me/22383398062" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100093168354814" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href="https://instagram.com/malievents223?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://www.linkedin.com/company/malievents/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href="https://twitter.com/mali_events223?s=21&t=cj88NEnob5qk0zynygDB2w" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="https://www.tiktok.com/@malievents?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </div>
                    </div>
                </div>
        <hr></hr>

        <div className="sb__footer-below">
            <div className="sb__footer-copyright">
                <p>
                    @{new Date().getFullYear()} MaliEvents. All right reserved.
                </p>
            </div>  
            
        </div>

            </div>
        </div>
    );
};