import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import { AboutUs } from "./pages/AboutUs";
import { Auth } from './pages/Auth';
import { ContactUs } from "./pages/Contact";
import { CreateEvent } from './pages/Create-event';
import { EventDetails } from './pages/EventDetails';
import { Home } from './pages/Home';
import { HowToUse } from "./pages/HowToUse";
import { Inscription } from "./pages/Inscription";
import { NosServices } from "./pages/NosServices";
import { Partenariat } from "./pages/Partenariat";
import { Policy } from "./pages/Policy";
import { SavedEvents } from './pages/Saved-events';
import { Terms } from "./pages/Terms";
export default function App() {
  return (
    <div className="App">
     <Router>
      <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:eventId" element={<EventDetails />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/saved-events" element={<SavedEvents />} />
          <Route path="/create-event" element={<CreateEvent />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/no-services" element={<NosServices />} />
          <Route path="/inscription" element={<Inscription />} />
          <Route path="/partenariat" element={<Partenariat />} />
          <Route path="/comment-ca-marche" element={<HowToUse />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      <Footer />
     </Router>
    </div>
  );
};
