import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faHeart, faShareSquare } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import link from "../icons/link.png";
import "../styles/EventDetails.css";
import { backendUrl } from "../utils/contants";

type Event = {
  title: string;
  eventProfileImage: string | undefined;
  startingDate: string;
  endingDate: string;
  eventAddress: string;
  eventDescription: string;
  eventImage: string | undefined;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  contactWhatSapp: string;
  contactLinkedIn: string;
  contactFacebook: string;
  contactInstagram: string;
  contactTwitter: string;
  contactTikTok: string;
  contactSponsorEmail: string;
  contactSponsorPhone: string;
  userOwner: string;
  sponsors: string[];
}

type EventProps = {
  data: Event
}

const EventDisplayer = ({ data }: EventProps): JSX.Element => (
  <div className="event-displayer">
    <React.Fragment>
      <h1 className="event-displayer-title">{data.title}</h1>
      <img className="event-displayer-profile-image" src={data.eventProfileImage} alt="" />
      <h1 className="event-displayer-dates">Du {new Date(data.startingDate).toLocaleDateString()} au {new Date(data.endingDate).toLocaleDateString()} </h1>
      <h1 className="event-displayer-address">À {data.eventAddress}</h1>
      <p className="event-details-description">{data.eventDescription}</p>
      <img className="event-displayer-event-image" src={data.eventImage} alt="" />
      <p className="event-details-contact-email">Plus d'information sur l'événément: {data.contactEmail}</p>
    </React.Fragment>
  </div>
)

type SharePagePopupProps = {
    trigger: boolean;
    onClose: () => void;
    children: React.ReactNode;
}
  
export function SharePagePopup(props: SharePagePopupProps) {
  return props.trigger ? (
    <div className="popup">
      <div className="inner-popup">    
        {props.children}
      </div>
    </div>
  ) : null;
}
type SponsorPagePopupProps = {
    trigger: boolean;
    onClose: () => void;
    children: React.ReactNode;
}
  
export function SponsorPagePopup(props: SponsorPagePopupProps) {
  return props.trigger ? (
    <div className="sponsor-popup">
      <div className="sponsor-inner-popup">    
        {props.children}
      </div>
    </div>
  ) : null;
}
  

export function EventDetails() {
  const params = useParams();
  const eventId = params.eventId ?? undefined;
  const userId = window.localStorage.getItem("userId") || "";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  
  const shareUrl = `http://www.mali-events.com/${eventId}`;

  const [event, setEvent] = useState<Event | undefined>(undefined);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showSponsorPopup, setShowSponsorPopup] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(true);

  const isLogged = window.localStorage.getItem("token") !== undefined && window.localStorage.getItem("token") !== null;

  useEffect(() => {
    const fetchEventData = async () => {
      const axiosFecthEventsRep = await axios.get<Event>(`${backendUrl}/api/event/${eventId}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${window.localStorage.getItem("token")}`
        },
      });
      const relatedEvent = axiosFecthEventsRep.data;
      setEvent(relatedEvent);
    };
    fetchEventData();
  }, [eventId]);

  if (event === undefined) {
    return <p>loading...</p>;
  }

  function handleCopyLink() {
    navigator.clipboard.writeText(window.location.href);
  }

  // Toggle the Share popup visibility
  const togglePopup = () => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  const handleEventSaving = async () => {
    if(isLogged){
      setIsSaved(!isSaved);
      try {
        const response = await axios.put(
          `${backendUrl}/api/event/saveEvent`,
          {
            eventId: eventId,
          },
          {
            headers: {
              'Authorization': `Bearer ${window.localStorage.getItem("token")}`
            },
          }
        );
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }
    else{
      alert("Vous devez vous connecté pour enregistrer cet événement");
    }
};
// Toggle the Sponsor popup visibility
const toggleSponsorPopup = () => {
  setShowSponsorPopup((prevShowPopup) => !prevShowPopup);
};



  const handleEventUnSaving =async () => {
    if (isLogged) {
      setIsSaved(!isSaved);
      try {
        const response = await axios.put(
          `${backendUrl}/api/event/unSaveEvent`,
          {
            eventId: eventId,
          },
          {
            headers: {
              'Authorization': `Bearer ${window.localStorage.getItem("token")}`
            },
          }
        );
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }
    else{
      alert("Vous devez vous connecté pour ne plus enregistrer cet événement");
    }  
  }
  
  const handleDeleteEvent = async () => {
    try {
      const response = await axios.delete(`${backendUrl}/api/event/${eventId}`,
       {
            headers: {
              'Authorization': `Bearer ${window.localStorage.getItem("token")}`
            },
          });
      alert("Événement supprimé");
      console.log(response.data);
      navigate("/");
    } catch (error) {
      console.error(error);
      alert("Impossible de supprimer cet événement !");
    }
  }

  return (
    <div>
        <EventDisplayer data={event} />

        { isSaved ?(
              <div className="social-media-sponsor-icons" onClick={handleEventSaving}>
                <p className="heart-icon">
                  <FontAwesomeIcon icon={faHeart} />
                </p>
              </div>)
          :
          <button className="event-details-save-button" onClick={handleEventUnSaving}>Ne plus enregistrer cet événement</button>
        }
        <div className="share">
        <h1>Partager cet événement</h1>
        
        <div className="social-media-sponsor-icons" onClick={togglePopup}>
          <p className="share-icon">
            <FontAwesomeIcon icon={faShareSquare} />
          </p>
        </div>
        

        {showPopup && (
          <SharePagePopup trigger={showPopup} onClose={togglePopup}>
              <FacebookShareButton className="facebook-share-button" url={shareUrl} quote={"MaliEvents"}>
                <FacebookIcon />
              </FacebookShareButton>

              <WhatsappShareButton className="whatsapp-share-button" url={shareUrl} quote={"MaliEvents"}>
                <WhatsappIcon />
              </WhatsappShareButton>
              <LinkedinShareButton className="linkedin-share-button" url={shareUrl} quote={"MaliEvents"}>
                <LinkedinIcon />
              </LinkedinShareButton>
              <EmailShareButton className="email-share-button" url={shareUrl} quote={"MaliEvents"}>
                <EmailIcon />
              </EmailShareButton> 
              <img className="copy-link-share-icon" src={link} alt="" onClick={handleCopyLink} style={{ width: "70px", cursor: "pointer" }} />
          </SharePagePopup>
        )}
        {
        isLogged && event.userOwner === userId && (
            <button className="event-details-delete-button" onClick={handleDeleteEvent}>Supprimer cet événement</button>
          )
        }
      <button className="event-details-sponsor-button"  onClick={toggleSponsorPopup}>Sponsoriser cet événement</button>
      {showSponsorPopup && (
          <SponsorPagePopup trigger={showSponsorPopup} onClose={toggleSponsorPopup}>

              <div className="social-media-sponsor-icons">
                <a href="https://wa.me/22383398062" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faWhatsapp} />
                </a>
                
                <a href="mailto:malievents223@gmail.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                
                <a className="malievents-email" href="mailto:malievents223@gmail.com" target="_blank" rel="noopener noreferrer">
                  malievents223@gmail.com
                </a>
              </div>
          </SponsorPagePopup>
        )}
      </div>
      {event.sponsors.length > 0 && (
        <div className="sponsors-section">
          <h1>Sponsors Officiels</h1>
          <div className="sponsors-grid">
            {event.sponsors.map((sponsorUrl, index) => (
              <img key={index} src={sponsorUrl} alt={`Sponsor ${index + 1}`} className="sponsor-logo" />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
