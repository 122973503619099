import "../styles/NosServices.css";

export function NosServices() {
  return (
    <div className="nos_services">
      <h1>Nos Services</h1>
      <div id="publication-section">
        <h2>Publication d'événements</h2>
        <p>
          Vous avez un événement à promouvoir ? MaliEvents offre un service de publication
          d'événements complet. Vous pouvez facilement créer une annonce pour votre événement,
          y ajouter tous les détails pertinents tels que la date, l'emplacement, la description
          et les modalités de participation. Votre événement sera ensuite visible par notre
          communauté d'utilisateurs intéressés par les événements au Mali.
        </p>
      </div>
      <div id="sponsorisation-section">
        <h2>Sponsorisation d'événements</h2>
        <p>
          Vous souhaitez donner une plus grande visibilité à votre entreprise en associant votre
          marque à des événements populaires ? MaliEvents propose un service de sponsorisation
          d'événements. Vous pouvez choisir parmi une sélection d'événements à sponsoriser et
          bénéficier d'une exposition accrue, d'une reconnaissance de marque et d'une relation
          privilégiée avec les organisateurs. Notre équipe vous accompagnera tout au long du
          processus de sponsorisation pour vous assurer une expérience réussie.
        </p>
      </div>
      <div id="ticket-selling-section">
        <h2>Vente et gestion de tickets</h2>
        <p>
          MaliEvents est en train de développer un nouveau service de vente et de gestion de tickets
          sur sa plateforme. Bientôt, vous pourrez vendre et gérer vos tickets d'événements en ligne
          grâce à notre système sécurisé et convivial. Vous pourrez offrir une expérience sans tracas
          à vos participants en leur permettant d'acheter des tickets en ligne, de recevoir des
          confirmations instantanées et de bénéficier d'un processus d'enregistrement fluide le jour
          de l'événement.
        </p>
      </div>
      <div>
        <h2>Conseils et support</h2>
        <p>
          Besoin d'aide pour organiser votre événement ? MaliEvents met à votre disposition une
          équipe d'experts qui vous conseillera et vous accompagnera tout au long du processus. Que
          ce soit pour la planification, la promotion, la logistique ou la gestion des inscriptions,
          nous sommes là pour vous fournir les meilleurs conseils et vous aider à garantir le succès
          de votre événement. Nous nous engageons à vous offrir un support personnalisé et efficace
          pour répondre à tous vos besoins.
        </p>
      </div>
    </div>
  );
}
