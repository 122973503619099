import { Link } from "react-router-dom";
import { Signup } from "../inside_components/Signup";
import "../styles/Auth.css";

export function Inscription() {
    return(
        <div className="auth">
            <h1 className="logo">
                <span className="green">M</span>
                <span className="green">a</span>
                <span className="green">l</span>
                <span className="green">i</span>
                <span className="yellow">E</span>
                <span className="yellow">v</span>
                <span className="yellow">e</span>
                <span className="red">n</span>
                <span className="red">t</span>
                <span className="red">s</span>
            </h1>
            <Signup />
            <p className="create-account">Déjà inscrit ? 
                <span className="green-text">
                    <Link className="green-text" to="/auth"> connectez-vous</Link>
                </span>
            </p>

            

        </div>
    );
};


