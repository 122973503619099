import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/Home.css";
import { backendUrl } from '../utils/contants';

type Event = {
    _id: string;
    title: string;
    eventProfileImage: string | undefined;
    startingDate: string;
    endingDate: string;
    eventAddress: string;
    eventDescription: string;
    eventImage: string | undefined;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactWhatSapp: string;
    contactLinkedIn: string;
    contactFacebook: string;
    contactInstagram: string;
    contactTwitter: string;
    contactTikTok: string;
    contactSponsorEmail: string;
    contactSponsorPhone: string;
}

type EventProps = {
    data: Event
}

const EventDisplayer = ({ data }: EventProps): JSX.Element => (
    <Link to={`/${data._id}`} className="event-link">
        <div className="sory-container">
            <h1 className="event-title">{data.title}</h1>
            <img className="event-image" src={data.eventProfileImage} alt="" />
            <h4 className="event-dates">
                Du {new Date(data.startingDate).toLocaleDateString()} au {new Date(data.endingDate).toLocaleDateString()}
            </h4>
            <h4 className="event-address">À {data.eventAddress}</h4>
        </div>
    </Link>
);

export function Home() {
    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        const fetchAllEvents = async () => {
            const axiosFecthEventsRep = await axios.get<Event[]>(`${backendUrl}/api/event/`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${window.localStorage.getItem("token")}`
                },
            });
            const events = axiosFecthEventsRep.data;

            // Reverse the events before setting them in the state
            setEvents(events.reverse());
        };
        fetchAllEvents();
    }, []);

    return (
        <div className="events-container">
            {events.map((event) => (
                <EventDisplayer key={event._id} data={event} />
            ))}
        </div>
    );
};
