import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";
import { backendUrl } from "../utils/contants";

export function Login(){
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const navigate = useNavigate();

    async function onSubmit(event: { preventDefault: () => void; }){
        event.preventDefault();
        try {
            
            const response = await axios.post(`${backendUrl}/api/auth/login`,
            {
                email:username, 
                password,
            });
            window.localStorage.setItem("userId", response.data.userId);
            window.localStorage.setItem("token", response.data.token);
            navigate("/");
            alert("Vous êtes connecté!");
        } catch (error) {
            alert("Connexion échouée");
            console.error(error);
        }
    }

    return(
        <Form 
            username={username}
            setUsername={setUsername} 
            password={password} 
            setPassword={setPassword} 
            label="Connexion"
            onSubmit={onSubmit}/>
    );
};



interface FormProps{
    username: string;
    setUsername: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}

function Form(props: FormProps){
    return(
        <div className="auth-container">
            <form onSubmit={props.onSubmit}>
                <h2> {props.label}</h2>
                <div className="form-group">
                    <label htmlFor="username">Email <span className="required-field">*</span></label>
                    <input 
                        className="form-input"
                        type="text"
                        id="username" 
                        placeholder="prenom.nom@exemple.ml"
                        value={props.username}
                        onChange={(event) => props.setUsername(event.target.value)} />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Mot de passe <span className="required-field">*</span> </label>
                    <input 
                        className="form-input"
                        type="password" 
                        id="password" 
                        placeholder="mot de passe"
                        value={props.password}
                        onChange={(event) => props.setPassword(event.target.value)} />
                </div>
                <button className="form-button" type="submit">{props.label}</button>
            </form>
        </div>
    );
};