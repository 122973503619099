import { Link } from "react-router-dom";
import "../styles/Policy.css";

export function Policy() {
  return (
    <div className="policy">
        <h1>Politique de confidentialité</h1>
        <p className="contract">Ce contrat a ete modifie pour la derniere le 16 Juillet 2023.</p>
        <p> Chez MaliEvents, la protection de vos données personnelles est un sujet que nous prenons très au sérieux. 
            Cette politique de confidentialité a pour but de vous informer sur la manière dont nous collectons, 
            utilisons et partageons vos informations lorsque vous utilisez notre site web et nos services.</p>
        <div id="information-collection">
            <h2>Informations que nous collectons</h2>
            <p>
                Nous collectons des informations vous concernant lorsque vous vous inscrivez sur notre site, 
                utilisez nos services ou remplissez un formulaire sur notre site. Les informations que nous 
                collectons peuvent inclure votre nom, votre adresse e-mail, votre numéro de téléphone et votre 
                adresse postale.
            </p>
        </div>
        <div id="usage-of-information">
            <h2>Utilisation de vos informations</h2>
            <p>
                Nous utilisons les informations que nous collectons afin de fournir et améliorer nos services, 
                de vous contacter en réponse à vos demandes de renseignements et de gérer votre compte.
            </p>
        </div>
        <div id="sharing-of-information">
            <h2>Partage de vos informations</h2>
                <p>
                    Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement, 
                    sauf dans les cas suivants :
                    <ol>
                        <ul><strong className="share-information-strong">1. </strong>
                        Lorsque cela est nécessaire pour fournir un service que vous avez demandé.</ul>
                        <ul><strong className="share-information-strong">2. </strong>
                        Lorsque cela est requis par la loi ou pour protéger nos droits.</ul>
                    </ol>
                </p>
        </div>
        <div>
          <h2>Sécurité de vos informations</h2>
          <p>
            Nous prenons des mesures de sécurité pour protéger vos informations contre l'accès, 
            l'utilisation ou la divulgation non autorisés. Nous utilisons également des mesures de
            sécurité pour protéger vos informations contre les pertes, les destructions accidentelles 
            et les altérations.
          </p>
        </div>
        <div>
            <h2>Modification de notre politique de confidentialité</h2>
            <p>
            Nous pouvons mettre à jour notre politique de confidentialité de temps en temps. 
            Nous vous informerons de tout changement significatif en publiant la nouvelle politique sur cette page.
            </p>
        </div>

        <div>
            <h2>Contact</h2>
            <p>
                Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, 
                n'hésitez pas à nous contacter à l'adresse suivante : malievents223@gmail.com
            </p>
            <p>

            <p > 
                    Pour plus d'information sur comment nous contacter, visiter la page
                    <span className="policy-contact-us">
                        <Link className="policy-contact-us" to="/contact-us"> contactez-nous.</Link>
                    </span>
            </p>
            </p>
        </div>
    </div>
  );
}
