import "../styles/Partenariat.css";

export function Partenariat() {
  return (
    <div className="partenariat">
      <h1>Nos Partenariats</h1>
      <div>
        <h2>Partenariat de Sponsorisation d'événements</h2>
        <p>
          MaliEvents peut collaborer avec des entreprises pour parrainer et promouvoir 
          leurs événements sur la plateforme. Ce partenariat offre une visibilité accrue, 
          une reconnaissance de marque et une connexion directe avec le public ciblé.
        </p>
      </div>
      <div>
        <h2>Co-marketing et Co-branding</h2>
        <p>
          MaliEvents peut s'associer à d'autres entreprises ou organisations pour une 
          co-marketing et un co-branding d'événements. Cette collaboration peut inclure 
          des campagnes promotionnelles communes, des supports marketing partagés et des
           opportunités de branding mutuellement bénéfiques.
        </p>
      </div>
      <div>
        <h2>Alliances Stratégiques</h2>
        <p>
          MaliEvents peut établir des alliances stratégiques avec des lieux d'événements, 
          des plateformes de billetterie, des services de restauration, des fournisseurs 
          d'équipements audiovisuels et d'autres entreprises liées aux événements. 
          Ce partenariat peut créer une expérience événementielle complète et harmonieuse 
          pour les utilisateurs, tout en générant des avantages mutuels pour les deux parties.
        </p>
      </div>
      <div>
        <h2>Engagement Communautaire</h2>
        <p>
          MaliEvents peut collaborer avec des communautés locales, des organisations à but non 
          lucratif et des entités gouvernementales pour soutenir et promouvoir des événements et 
          des initiatives communautaires. Ce partenariat peut favoriser l'engagement communautaire, 
          l'impact social et le développement culturel.
        </p>
      </div>
      <div>
        <h2>Offres et Réductions Exclusives</h2>
        <p>
          MaliEvents peut s'associer à des entreprises pour offrir des offres exclusives, 
          des réductions ou des forfaits spéciaux aux participants d'événements. 
          Ce partenariat peut offrir une valeur ajoutée aux participants tout en générant 
          du trafic et en attirant de nouveaux clients pour les entreprises partenaires.
        </p>
      </div>
    </div>
  );
}
